export async function restGet(url, params) {
    const urlParams = new URLSearchParams(params)
    const urlWithParams = `${url}?${urlParams.toString()}`
    return fetch(urlWithParams, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
        }
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            return response.json()
        })
        .catch((error) => {
            throw new Error('Verification failed:', error)
        })
}

export async function restPost(url, body) {
    return fetch(url, {
        method: 'POST', // Method specifies the HTTP method
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content')
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            return response.json()
        })
        .catch((error) => {
            throw new Error('Verification failed:', error)
        })
}
