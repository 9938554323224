import {
    SET_CATEGORIES,
    SET_CATEGORY,
    SET_FILTERS,
    SET_FILTER_LABELS,
    SET_CATEGORY_FILTER
} from '../mutation-types'
import axios from "axios"

const state = {
    filters: [],
    filterLabels: [],
    categories: [],
    selectedCategory: {},
    selectedFilters: []
}

const getters = {
    filters: state => state.filters,
    filterLabels: state => state.filterLabels,
    categories: state => state.categories,
    selectedCategory: state => state.selectedCategory,
}

const actions = {
    async getCategoryProducts({ commit, state }, page) {
        if (page === 0 || typeof page === 'undefined') {
            page = 1
        }
        
        await axios.post('/api/v1/category-products', {
            'category_name': state.selectedCategory.category_name,
            'filters': state.selectedFilters,
            'page': page,
        }).then(({ data }) => {
            commit(SET_CATEGORY, data.current_category)
        })
    },
    setFilters({ commit }, filters) {
        commit(SET_FILTERS, filters)
    },
    setFilterLabels({ commit }, filters) {
        commit(SET_FILTER_LABELS, filters)
    },
    setCategories({ commit }, categories) {
        commit(SET_CATEGORIES, categories)
    },
    setSelectedCategory({ commit }, category) {
        commit(SET_CATEGORY, category)
    },
    checkFilter({ commit }, filters) {
        commit(SET_CATEGORY_FILTER, {
            'parentIndex': filters.parentIndex,
            'filterIndex': filters.filterIndex,
        })
    }
}

const mutations = {
    [SET_CATEGORIES](state, categories) {
        state.categories = categories
    },
    [SET_CATEGORY](state, category) {
        state.selectedCategory = category
    },
    [SET_FILTERS](state, filters) {
        state.filters = filters
    },
    [SET_FILTER_LABELS](state, labels) {
        state.filterLabels = labels
    },
    [SET_CATEGORY_FILTER](state, filters) {
        state.filters[filters.parentIndex][filters.filterIndex].checked = !state.filters[filters.parentIndex][filters.filterIndex].checked
        state.selectedFilters = []

        state.filters.forEach(filter => {
            for (const value in filter) {
                if (!filter[value].checked) continue

                filter[value].filter.replace(' ', '-').toLowerCase()
                filter[value].value.replace(' ', '-').toLowerCase()
                state.selectedFilters.push(filter[value])
            }
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
