import { restPost } from './Rest'

export async function blockchainLogin(accountName) {
    return restPost('/bc-login', {
        account: accountName
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw new Error('Verification failed:', error)
        })
}

export async function blockchainLogout() {
    return restPost('/bc-logout')
        .then(() => {
            window.location.href = '/'
        })
        .catch((error) => {
            throw new Error('Verification failed:', error)
        })
}
