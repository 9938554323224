import {
    CLOSE_LOGIN_MODAL,
    SET_ETH_ADDRESS,
    SET_ETH_RATE,
} from '../mutation-types'
import axios from "axios";

const state = {
    ethAddress: null,
    ethRate: 0
}

const getters = {
    ethAddress: state => state.ethAddress,
    ethRate: state => state.ethRate,
}

const actions = {
    async setEthRate({commit}) {
        return new Promise( async (resolve, reject) => {
            await axios.get('https://api.coinbase.com/v2/exchange-rates?currency=ETH').then(async (response) => {
                commit(SET_ETH_RATE, response.data.data.rates.USD)
                resolve()
            }).catch((response) => {
                reject(response)
            })
        })
    },
    async setEthAddress({commit, state}) {
        await window.ethereum.enable()
        const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
        const account = accounts[0]

        if (account.length > 0) {
            commit(SET_ETH_ADDRESS, account)
        } else {
            await window.ethereum.on('accountsChanged', function (accounts) {
                commit(SET_ETH_ADDRESS, accounts[0])
            })
        }
    },
}

const mutations = {
    [SET_ETH_ADDRESS](state, address) {
        state.ethAddress = address
    },
    [SET_ETH_RATE](state, rate) {
        state.ethRate = rate
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
