import {
    SET_OPTIONS,
    SET_PRODUCT,
    SET_PRODUCT_COLOR,
    SET_SIZES,
    SET_COLORS,
    SET_CURRENT_VARIANT,
} from '../mutation-types'

const state = {
    options: [
        {
            Name: '',
            Values: [
                {
                    Name: '',
                    Value: ''
                }
            ],
        }
    ],
    variants: [{
        sku: null,
        price: null,
        allowed_images: 0,
        templates: []
    }],
    currentVariant: {
        sku: null,
        price: null,
        allowed_images: 0,
        templates: {
            Options: [
                {
                    Spaces: []
                }
            ]
        }
    },
    selectedProduct: {
        categories: [],
    },
    colors: [],
    sizes: [],
    options: [
        {
            price: 0,
        }
    ],
    originalVariants: [],
    filters: [],
    usedFilters: {},
}

const getters = {
    sizes: state => state.sizes,
    colors: state => state.colors,
    options: state => state.options,
    selectedProduct: state => state.selectedProduct,
    currentVariant: state => state.currentVariant,
}

const actions = {
    setSelectedVariant({commit}, variant) {
        commit(SET_CURRENT_VARIANT, variant)
    },
    setSelectedProduct({commit}, product) {
        commit(SET_PRODUCT, product)
    },
    setSizes({commit}, sizes) {
        commit(SET_SIZES, sizes)
    },
    setColors({commit}, colors) {
        commit(SET_COLORS, colors)
    },
    setOptions({commit}, options) {
        commit(SET_OPTIONS, options)
    },
}

const mutations = {
    [SET_OPTIONS](state, options) {
        state.options = options
    },
    [SET_PRODUCT](state, product) {
        state.selectedProduct = product
    },
    [SET_SIZES](state, sizes) {
        state.sizes = sizes
    }, 
    [SET_COLORS](state, colors) {
        state.colors = colors
    },
    [SET_PRODUCT_COLOR](state, color) {
        state.selectedProduct.image = '/images/' +
            state.selectedProduct.name.toLowerCase().replace(" ", "") +
            "-" + color.toLowerCase() + "-front-sample.png"
    },
    [SET_CURRENT_VARIANT](state, variant) {
        state.currentVariant = variant
    }
}


export default {
    state,
    getters,
    actions,
    mutations,
}
