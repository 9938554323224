import {
    CLOSE_LOGIN_MODAL,
    OPEN_LOGIN_MODAL,
    SET_WAX,
    SET_WAX_WALLET,
    SET_WAX_USER,
    SET_WAX_RATE,
    SET_WAX_BALANCE
} from '../mutation-types'
import axios from 'axios'
import { JsonRpc } from 'eosjs'

const state = {
    rate: null,
    wax: {},
    loginModal: false,
    wallet: '',
    user: {
        accountName: null
    },
    balance: '0'
}

const getters = {
    waxRate: (state) => state.rate,
    wax: (state) => state.wax,
    wallet: (state) => state.wallet,
    waxUser: (state) => state.user,
    isLoginModalActive: (state) => state.loginModal,
    waxBalance: (state) => state.balance
}

const actions = {
    setWaxUser({ commit }, user) {
        commit(SET_WAX_USER, user)
    },
    setCurrentWallet({ commit }, wallet) {
        commit(SET_WAX_WALLET, wallet)
    },
    setCurrentWaxObject({ commit }, wax) {
        commit(SET_WAX, wax)
    },
    openLoginModal({ commit }) {
        commit(OPEN_LOGIN_MODAL)
    },
    closeLoginModal({ commit }) {
        commit(CLOSE_LOGIN_MODAL)
    },
    async setWaxRate({ commit }) {
        await axios
            .get('https://api.coinbase.com/v2/exchange-rates?currency=WAXP')
            .then(async (response) => {
                commit(SET_WAX_RATE, response.data.data.rates.USD)
            })
    },
    async setWaxAddress({ dispatch }, userAccount) {
        dispatch('setCurrentWallet', userAccount)
        dispatch('updateBalance')
        setInterval(dispatch('updateBalance'), 30000)
    },
    async updateBalance({ state, commit }) {
        let host = 'wax.greymass.com'
        // if (window.environment !== 'production') {
        //     host = 'http://waxtest.eosdac.io'
        // }
        let protocol = 'https'
        let port = '443'

        try {
            const rpc = new JsonRpc(`${protocol}://${host}:${port}`)
            const accountName = await state.wallet
            if (!accountName) throw new Error('Account name is undefined')

            const data = await rpc.get_account(accountName)
            const balance = data.core_liquid_balance
            commit(SET_WAX_BALANCE, balance)
        } catch (e) {
            console.error(e)
            commit(SET_WAX_BALANCE, 0)
        }
    }
}

const mutations = {
    [SET_WAX_WALLET](state, wallet) {
        state.wallet = wallet
    },
    [SET_WAX](state, wax) {
        state.wax = wax
    },
    [OPEN_LOGIN_MODAL](state) {
        state.loginModal = true
    },
    [CLOSE_LOGIN_MODAL](state) {
        state.loginModal = false
    },
    [SET_WAX_USER](state, user) {
        state.user = user
    },
    [SET_WAX_RATE](state, rate) {
        state.rate = rate
    },
    [SET_WAX_BALANCE](state, balance) {
        const matches = balance.match(/([0-9.]+)\s*(\D+)/)
        if (!matches) {
            state.balance = '0'
            return
        }

        const numberPart = parseFloat(matches[1])
        const alphaPart = matches[2]
        const roundedNumber = numberPart.toFixed(2)

        state.balance = `${roundedNumber} ${alphaPart}`
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
