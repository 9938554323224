import Vue from 'vue'
import Vuex from 'vuex'
import App from './modules/app.js'
import Wax from './modules/wax.js'
import Eth from './modules/eth.js'
import Order from './modules/order.js'
import Loader from './modules/loader.js'
import Gooten from './modules/gooten.js'
import Address from './modules/address.js'
import Previews from './modules/previews.js'
import Categories from './modules/categories.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Wax,
        Eth,
        App,
        Order,
        Gooten,
        Loader,
        Address,
        Previews,
        Categories
    },
})
