<template>
    <div class="wax-login">
        <!-- Trigger Button -->
        <button
            class="btn btn-danger btn-lg"
            @click="toggleModal"
            v-show="!loggedIn"
        >
            Login with Wallet
        </button>

        <!-- Modal -->
        <div
            class="modal show"
            style="display: block; background-color: rgba(0, 0, 0, 0.5)"
            v-show="isModalActive"
            tabindex="-1"
            aria-labelledby="walletModalLabel"
            aria-hidden="true"
            @click="closeModal"
        >
            <div class="modal-dialog" @click.stop>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Choose Wallet</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            @click="closeModal"
                        >
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <button
                            class="btn btn-secondary w-100 mb-2"
                            @click="loginWithWax"
                        >
                            Login with WAX Cloud Wallet
                        </button>
                        <button
                            class="btn btn-secondary w-100 mb-2"
                            @click="loginWithAnchor"
                        >
                            Login with Anchor
                        </button>
                        <button
                            class="btn btn-secondary w-100"
                            @click="loginWithWombat"
                        >
                            Login with Wombat
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnchorLink from 'anchor-link'
import ScatterJS from 'scatterjs-core'
import * as waxjs from '@waxio/waxjs/dist'
import { mapActions, mapGetters } from 'vuex'
import ScatterEOS from 'scatterjs-plugin-eosjs2'
import { blockchainLogin } from '../utilities/Auth'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'

export default {
    name: 'WaxLogin',
    props: {
        loggedIn: {
            Boolean,
            default: false
        },
        redirect: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            blockchainUrl: 'wax.greymass.com',
            isModalActive: false,
            link: null,
            scatter: null
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 991
        },
        ...mapGetters({
            wax: 'wax',
            wallet: 'wallet'
        })
    },
    mounted() {
        // if (window.environment !== 'production') {
        //     this.blockchainUrl = 'waxtest.eosdac.io'
        // }
        this.initializeWallets()
        this.autoLoginWAX()
    },
    methods: {
        async appLogin() {
            await blockchainLogin(this.wallet)
                .then(() => {
                    window.location.href = this.redirect
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        initializeWallets() {
            const wax = new waxjs.WaxJS({
                rpcEndpoint: 'https://wax.greymass.com'
            })

            this.setCurrentWaxObject(wax)

            const transport = new AnchorLinkBrowserTransport()

            this.link = new AnchorLink({
                transport,
                chains: [
                    {
                        chainId:
                            '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
                        nodeUrl: 'https://wax.greymass.com/'
                    }
                ]
            })

            ScatterJS.plugins(new ScatterEOS())
        },
        async loginWithWax() {
            try {
                const userAccount = await this.wax.login()
                console.log('Logged in with WAX Cloud Wallet as:', userAccount)
                this.setWaxAddress(userAccount)
                this.closeModal()
                if (!this.loggedIn) {
                    this.appLogin()
                }
            } catch (error) {
                console.error('WAX Cloud Wallet login failed:', error)
            }
        },
        async autoLoginWAX() {
            let isAutoLoginAvailable = await this.wax.isAutoLoginAvailable()
            if (isAutoLoginAvailable) {
                this.setWaxAddress(this.wax.userAccount)
            }
        },
        async loginWithAnchor() {
            try {
                const identity = await this.link.login('yourAppName')
                console.log('Logged in with Anchor as:', identity.session.auth)
            } catch (error) {
                console.error('Anchor login failed:', error)
            }
        },
        async loginWithWombat() {
            if (!ScatterJS.scatter.connect('YourAppName')) {
                return console.error('Connection to Wombat failed')
            }
            try {
                const scatter = ScatterJS.scatter
                const requiredFields = { accounts: [network] }
                await scatter.getIdentity(requiredFields)
                const account = scatter.identity.accounts.find(
                    (x) => x.blockchain === 'eos'
                )
                console.log('Logged in with Wombat as:', account.name)
                scatter.forgetIdentity()
            } catch (error) {
                console.error('Wombat login failed:', error)
            }
        },
        toggleModal() {
            this.isModalActive = !this.isModalActive
        },
        closeModal() {
            this.isModalActive = false
        },
        ...mapActions({
            setWaxAddress: 'setWaxAddress',
            setCurrentWaxObject: 'setCurrentWaxObject'
        })
    }
}
</script>

<style scoped></style>
