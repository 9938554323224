import axios from "axios"

import {
    SET_CART, SET_ADDRESS, SET_ETH_ADDRESS, SET_SHIPPING_TOTAL,
} from '../mutation-types'

const state = {
    address: {}, shippingTotal: {}
}

const getters = {
    address: state => state.address, shippingTotal: state => state.shippingTotal,
}

const actions = {
    async setAddress({commit}, requestData) {
        await axios.post('/api/v1/addresses', {...requestData}).then(({data}) => {
            commit(SET_ADDRESS, data.address)
            commit(SET_SHIPPING_TOTAL, data.shipping_total)
            commit('Order/' + SET_CART, data.cart, {root: true})
        })
    },
}

const mutations = {
    [SET_ADDRESS](state, address) {
        state.address = address
    }, [SET_SHIPPING_TOTAL](state, total) {
        state.shippingTotal = total
    },
}

export default {
    state, getters, actions, mutations,
}
