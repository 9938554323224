import axios from "axios"
import {
    OPEN_LOADER,
    CLOSE_LOADER
} from '../mutation-types'

const state = {
    loader: false,
}

const getters = {
    loader: state => state.loader,
}

const actions = {
    openLoader({commit}) {
        commit(OPEN_LOADER)
    },
    closeLoader({commit}) {
        commit(CLOSE_LOADER)
    },
}

const mutations = {
    [OPEN_LOADER](state) {
        state.loader = true
    },
    [CLOSE_LOADER](state) {
        state.loader = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
