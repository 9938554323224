import {
    ADD_PREVIEW,
    RESET_PREVIEW
} from '../mutation-types'

const state = {
    previewImages: []
}

const getters = {
    previewImages: state => state.previewImages,
}

const actions = {
    addPreview({commit}, preview) {
        commit(ADD_PREVIEW, preview)
    },
    resetPreviews({commit}) {
        commit(RESET_PREVIEW)
    }
}

const mutations = {
    [ADD_PREVIEW](state, preview) {
        state.previewImages.push(preview)
    }, 
    [RESET_PREVIEW] (state) {
        state.previewImages = []
    }
}


export default {
    state,
    getters,
    actions,
    mutations,
}
