var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wax-login" }, [
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loggedIn,
            expression: "!loggedIn",
          },
        ],
        staticClass: "btn btn-danger btn-lg",
        on: { click: _vm.toggleModal },
      },
      [_vm._v("\n        Login with Wallet\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isModalActive,
            expression: "isModalActive",
          },
        ],
        staticClass: "modal show",
        staticStyle: {
          display: "block",
          "background-color": "rgba(0, 0, 0, 0.5)",
        },
        attrs: {
          tabindex: "-1",
          "aria-labelledby": "walletModalLabel",
          "aria-hidden": "true",
        },
        on: { click: _vm.closeModal },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Choose Wallet"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-close",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "modal",
                      "aria-label": "Close",
                    },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v("\n                        X\n                    ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary w-100 mb-2",
                    on: { click: _vm.loginWithWax },
                  },
                  [
                    _vm._v(
                      "\n                        Login with WAX Cloud Wallet\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary w-100 mb-2",
                    on: { click: _vm.loginWithAnchor },
                  },
                  [
                    _vm._v(
                      "\n                        Login with Anchor\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary w-100",
                    on: { click: _vm.loginWithWombat },
                  },
                  [
                    _vm._v(
                      "\n                        Login with Wombat\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }