export const SET_PAGINATION = 'SET_PAGINATION'

export const ADD_PLOTS = 'ADD_PLOTS'
export const SET_PLOTS = 'SET_PLOTS'
export const SET_CURRENT_PLOT = 'SET_CURRENT_PLOT'

export const SET_WALLETS = 'SET_WALLETS'
export const ADD_WALLET = 'ADD_WALLET'
export const OPEN_PLOT_MODAL = 'OPEN_PLOT_MODAL'
export const CLOSE_PLOT_MODAL = 'CLOSE_PLOT_MODAL'

export const SET_USER = 'SET_USER'
export const SET_FILTER = 'SET_FILTER'
export const RESET_FILTER = 'RESET_FILTER'

export const SET_CURRENT_WAX_WALLET = 'SET_CURRENT_WAX_WALLET'
export const SET_CURRENT_WALLET_ADDRESS = 'SET_CURRENT_WALLET_ADDRESS'
export const OPEN_WALLET_MODAL = 'OPEN_WALLET_MODAL'
export const CLOSE_WALLET_MODAL = 'CLOSE_WALLET_MODAL'

export const SET_WAX = 'SET_WAX'
export const SET_WAX_WALLET = 'SET_WAX_WALLET'
export const LOGIN = 'LOGIN'
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL'
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL'
export const SET_WAX_USER = 'SET_WAX_USER'
export const SET_WAX_RATE = 'SET_WAX_RATE'
export const SET_WAX_BALANCE = 'SET_WAX_BALANCE'

export const SET_ETH_RATE = 'SET_ETH_RATE'
export const SET_ETH_ADDRESS = 'SET_ETH_ADDRESS'

export const SET_ADDRESS = 'SET_ADDRESS'
export const SET_SHIPPING_TOTAL = 'SET_SHIPPING_TOTAL'

export const SET_ORDER_ID = 'SET_ORDER_ID'
export const SET_CART_ID = 'SET_CART_ID'
export const SET_CART = 'SET_CART'

export const OPEN_LOADER = 'OPEN_LOADER'
export const CLOSE_LOADER = 'CLOSE_LOADER'

export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_SIZES = 'SET_PRODUCT_SIZES'
export const SET_COLORS = 'SET_PRODUCT_COLORS'
export const SET_PRODUCT_SIZE = 'SET_PRODUCT_SIZE'
export const SET_PRODUCT_COLOR = 'SET_PRODUCT_COLOR'
export const SET_PRODUCT_OPTION = 'SET_PRODUCT_OPTION'

export const ADD_PREVIEW = 'ADD_PREVIEW'
export const RESET_PREVIEW = 'RESET_PREVIEW'
export const CHANGE_PRODUCT_COLOR = 'CHANGE_PRODUCT_COLOR'

export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTER_LABELS = 'SET_FILTER_LABELS'
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER'
export const SET_CURRENT_VARIANT = 'SET_CURRENT_VARIANT'






