import Vue from 'vue'
import Vuex from 'vuex'
import 'core-js/stable'
import './utilities/Auth'
import lozad from 'lozad'
import Prism from 'prismjs'
import { Buffer } from 'buffer'
import './../scss/frontend.scss'
import 'regenerator-runtime/runtime'
import store from './store/index.js'
import 'prismjs/components/prism-scss'
import VueLazyLoad from 'vue-lazyload'
import Velocity from 'velocity-animate'
import 'prismjs/themes/prism-tomorrow.css'
import { mapActions, mapGetters } from 'vuex'
import 'prismjs/plugins/toolbar/prism-toolbar'
import 'prismjs/plugins/toolbar/prism-toolbar.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
import {
    faFacebook,
    faTwitter,
    faLinkedin,
    faDiscord,
    faGoogle
} from '@fortawesome/free-brands-svg-icons'

import {
    faPlus,
    faMinus,
    faLongArrowAltRight,
    faLongArrowAltLeft,
    faUser,
    faArrowDown,
    faArrowUp,
    faCheckCircle,
    faCaretDown,
    faArrowRight,
    faShippingFast,
    faHandHoldingHeart,
    faHeadset,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import WaxLogin from './components/WaxLogin.vue'

window.Buffer = Buffer

const ready = () => {
    if (document.querySelector('code')) {
        Prism.highlightAll()
    }
}

let environment = document.head.querySelector('meta[name="environment"]')
if (environment) {
    window.environment = environment.getAttribute('content')
} else {
    window.environment = 'development'
}

library.add(faLongArrowAltRight)
library.add(faLongArrowAltLeft)
library.add(faPlus)
library.add(faMinus)
library.add(faFacebook)
library.add(faTwitter)
library.add(faLinkedin)
library.add(faDiscord)
library.add(faGoogle)
library.add(faUser)
library.add(faArrowDown)
library.add(faArrowUp)
library.add(faCheckCircle)
library.add(faCaretDown)
library.add(faArrowRight)
library.add(faShippingFast)
library.add(faHandHoldingHeart)
library.add(faHeadset)
library.add(faChevronRight)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('wax-login', WaxLogin)
Vue.use(VueLazyLoad)
Vue.use(Vuex)

new Vue({
    el: '#app',
    data: {
        nonce: null,
        isLoginActive: true,
        userLoggedIn: false,
        balanceInterval: null,
        onWaxButtonClicked: false,
        blockchainUrl: 'wax.greymass.com',
        isMobile: window.innerWidth <= 991,
        menu: {
            active: window.innerWidth > 991
        },
        overlay: {
            active: false
        }
    },
    store,
    mounted() {
        const observer = lozad()
        observer.observe()
    },
    methods: {
        toggleMenu() {
            this.menu.active = !this.menu.active
        },
        beforeEnter(el) {
            if (this.isMobile) {
                this.overlay.active = true
            }
            el.style.display = 'block'
        },
        enter(el, done) {
            Velocity(
                el,
                {
                    marginLeft: 0
                },
                {
                    complete: done
                },
                {
                    duration: 500
                }
            )
        },
        leave(el, done) {
            Velocity(
                el,
                {
                    marginLeft: '-100%'
                },
                {
                    complete: done
                },
                {
                    duration: 500
                }
            ).then(() => {
                if (this.isMobile) {
                    this.overlay.active = false
                }
            })
        },
        afterLeave(el) {
            el.style.display = 'none'
        },
        loggedIn() {
            this.isLoginActive = false
            window.location.href = '/preview'
        }
    },
    components: {
        'app-login': () => import('./components/AppLogin.vue'),
        'mug-builder': () => import('./components/MugBuilder.vue'),
        burger: () => import('./components/utilities/Burger.vue'),
        'mug-checkout': () => import('./components/MugCheckout.vue'),
        account: () => import('./components/utilities/Account.vue'),
        'preview-order': () => import('./components/PreviewOrder.vue'),
        'product-search': () => import('./components/utilities/ProductSearch'),
        'single-product': () =>
            import('./components/products/SingleProduct.vue'),
        'product-categories': () =>
            import('./components/categories/ProductCategories'),
        'final-image-upload': () =>
            import('./components/utilities/FinalImageUpload.vue'),
        'collection-registration': () =>
            import('./components/CollectionRegistration.vue'),
        'process-gooten-order': () =>
            import('./components/utilities/ProcessGootenOrder.vue'),
        'mug-simple-preview': () =>
            import('./components/simple-preview/StaticMugSimplePreview')
    }
})
