import {
    SET_CART,
    SET_CART_ID,
    SET_ORDER_ID,
} from '../mutation-types'
import axios from "axios"

const state = {
    orderId: null,
    cartId: null,
    cart: {
        status: 'pending',
        total: 0,
        subTotal: 0,
        items: [
            {
                id: null,
                price: 10,
                image: null,
                order: {
                    quantity: 1,
                }
            }
        ],
        address: {
            line_1: '',
            line_2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        }
    },
}

const getters = {
    orderId: state => state.orderId,
    cartId: state => state.cartId,
    cart: state => state.cart,
}

const actions = {
    async createOrder({commit}, selectedVariant) {
        await axios.post('/api/v1/orders', {
            price: selectedVariant.price,
            sku: selectedVariant.sku,
        }).then(({data}) => {
            commit(SET_CART_ID, data.cart_id)
            commit(SET_ORDER_ID, data.order_id)
        })
    },
    async getSavedCart({commit}) {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        })

        return await axios.get('api/v1/checkout?cart_id=' + params.cart_id).then(({data}) => {
            commit(SET_CART, data.cart)
            commit(SET_CART_ID, data.cart.id)
        })
    },
    async setCart({commit}, cart) {
        commit(SET_CART, cart)
    },
    async updateCartItem({commit}, item) {
        await axios.put('/api/v1/cart-items/' + item.id, {
            item: item
        }).then(({data}) => {
            commit(SET_CART, data.cart)
        })
    },
}

const mutations = {
    [SET_CART_ID](state, cartId) {
        state.cartId = cartId
    },
    [SET_ORDER_ID](state, orderId) {
        state.orderId = orderId
    },
    [SET_CART](state, cart) {
        state.cart = cart
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
